import { IConfig, UnleashClient } from 'unleash-proxy-client';
import { APP_INITIALIZER, EnvironmentProviders, ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { DefaultUnleashGuard } from './guards';
import { DefaultUnleashResolver } from './resolvers';
import { UnleashDirective } from './unleash.directive';
import { UNLEASH_CLIENT } from './unleash.injector';
import { UnleashService } from './unleash.service';

export interface UnleashModuleOptions extends IConfig {}

@NgModule({
  declarations: [UnleashDirective],
  providers: [DefaultUnleashResolver, DefaultUnleashGuard],
  exports: [UnleashDirective],
})
export class UnleashModule {
  static forRoot(options: UnleashModuleOptions): ModuleWithProviders<UnleashModule> {
    const PROVIDERS: Array<Provider | EnvironmentProviders> = [
      {
        provide: UNLEASH_CLIENT,
        useFactory: () => {
          return new UnleashClient(options);
        },
      },
    ];

    PROVIDERS.push({
      provide: APP_INITIALIZER,
      useFactory(unleash: UnleashService) {
        return () => unleash.start();
      },
      deps: [UnleashService],
      multi: true,
    });

    return {
      ngModule: UnleashModule,
      providers: [...PROVIDERS],
    };
  }
}
