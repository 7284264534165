import { catchError, Observable, throwError } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { captureMessage } from '@sentry/browser';

import { APP_CONFIG } from '@supy/core';

import { EndpointConfig } from '../../config';

@Injectable()
export class SentryInterceptor<T, R> implements HttpInterceptor {
  constructor(@Inject(APP_CONFIG) private readonly config: EndpointConfig) {}

  intercept(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<R>> {
    const { apiUrl, apiUrlBff, apiUrlPrefix } = this.config;

    if (
      request.url.startsWith(`${apiUrl}${apiUrlPrefix}`) ||
      request.url.startsWith(`${apiUrlBff}${apiUrlPrefix}`) ||
      request.url.includes('/ws/')
    ) {
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleSentryError(request, error);
        }),
      );
    }

    return next.handle(request);
  }

  private handleSentryError<T>(request: HttpRequest<T>, error: HttpErrorResponse) {
    const message = (error.error as HttpErrorResponse)?.message;

    captureMessage(`${message ? message + ': ' : ''}${error.message}`, {
      extra: {
        url: request.url,
        statusCode: error.status,
        urlWithParams: request.urlWithParams,
        method: request.method,
        params: request.params,
        body: request.body,
        serializedBody: request.serializeBody(),
      },
      tags: {
        statusCode: error.status,
        apiError: true,
        customError: true,
        'http.status_code': error.status,
      },
    });

    return throwError(() => error);
  }
}
