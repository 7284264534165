import { ProcurementEventType } from '../enums';

export interface CreateRetailerMetadataRequest {
  readonly color?: string;
}

export interface CreateRetailerRequest {
  readonly name: string;

  readonly logoUrl?: string;

  readonly metadata?: CreateRetailerMetadataRequest;
}

export interface UpdateRetailerMetadataRequest {
  readonly color?: string;
}

export interface UpdateRetailerRequest {
  readonly name?: string;

  readonly logoUrl?: string;

  readonly metadata?: UpdateRetailerMetadataRequest;
}

export interface UploadImageRequest {
  readonly file: Blob;
}

export interface UploadRetailerImageResponse {
  readonly logoUrl: string;
}

interface GetAllowedEventDateLimitsRequest {
  readonly locationIds: string[];
  readonly retailerId: string;
}

export interface GetAllowedEventDateLimitsResponse {
  readonly minDate: string;
  readonly maxDate?: string;
}

export interface GetAllowedProcurementEventDateLimitsRequest extends GetAllowedEventDateLimitsRequest {
  readonly eventType: ProcurementEventType;
}

export interface AllowedEventDateLimits {
  readonly minimumDate: Date;
  readonly maximumDate: Date | null;
}

export function deserializeAllowedEventDateLimits(data: GetAllowedEventDateLimitsResponse): AllowedEventDateLimits {
  return {
    minimumDate: new Date(data.minDate),
    maximumDate: data.maxDate ? new Date(data.maxDate) : null,
  };
}
