import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxToastModule } from '@infragistics/igniteui-angular';

import { VersionCheckComponent } from './components';
import { VersionCheckService } from './services';

@NgModule({
  imports: [CommonModule, IgxToastModule],
  declarations: [VersionCheckComponent],
  providers: [VersionCheckService],
  exports: [VersionCheckComponent],
})
export class VersionCheckModule {}
