import DOMPurify from 'dompurify';
import { trustedTypes } from 'trusted-types';

interface CreateHTMLOptions {}

interface CreateScriptOptions {}

interface CreateScriptURLOptions {}

if (!window.trustedTypes) {
  Object.defineProperties(window, {
    trustedTypes: {
      value: trustedTypes,
    },
  });
}

const DEFAULT_POLICY_NAME = 'default';

window.trustedTypes.createPolicy(DEFAULT_POLICY_NAME, {
  createHTML(input: string, options: CreateHTMLOptions): string {
    return DOMPurify.sanitize(input, {
      RETURN_TRUSTED_TYPE: false,
      USE_PROFILES: { html: true, svg: true },
    });
  },
  createScript(input: string, options: CreateScriptOptions): string {
    return DOMPurify.sanitize(input, {
      RETURN_TRUSTED_TYPE: false,
    });
  },
  createScriptURL(input: string, options: CreateScriptURLOptions): string {
    return DOMPurify.sanitize(input, {
      RETURN_TRUSTED_TYPE: false,
    });
  },
});
