import { introBeforeChangeCallback } from 'intro.js/src/packages/tour/callback';
import { TourOptions } from 'intro.js/src/packages/tour/option';
import { TourStep } from 'intro.js/src/packages/tour/steps';

import { Nullable } from '@supy/core';

export type OnboardingOptions = Partial<TourOptions>;
export type OnboardingStep = Partial<TourStep>;
export type OnboardingBeforeChangeCallback = Nullable<introBeforeChangeCallback>;

export const DEFAULT_ONBOARDING_OPTIONS: OnboardingOptions = {
  exitOnOverlayClick: false,
  disableInteraction: true,
  autoPosition: true,
  nextLabel: $localize`:@@next:Next`,
  prevLabel: $localize`:@@back:Back`,
  doneLabel: $localize`:@@done:Done`,
};

export const DEFAULT_ONBOARDING_STEP_OPTIONS: OnboardingStep = {
  title: $localize`:@@grns.newFeatureAlert:New Feature Alert`,
};
