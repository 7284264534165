import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { extractCommitHash, VersionCheckConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

@Component({
  selector: 'supy-aside-layout',
  templateUrl: './aside-layout.component.html',
  styleUrls: ['./aside-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideLayoutComponent {
  protected versionHash: string | undefined;

  private readonly config = inject<VersionCheckConfig>(APP_CONFIG);

  constructor() {
    this.versionHash = extractCommitHash(this.config.versionCheck.hash);
  }
}
