import { map, Observable, ReplaySubject, startWith } from 'rxjs';
import { EVENTS, IMutableContext, UnleashClient } from 'unleash-proxy-client';
import { Inject, Injectable, OnDestroy } from '@angular/core';

import { UNLEASH_CLIENT } from './unleash.injector';

@Injectable({
  providedIn: 'root',
})
export class UnleashService implements OnDestroy {
  readonly #statusChanges$ = new ReplaySubject<void>(1);

  constructor(@Inject(UNLEASH_CLIENT) private readonly unleash: UnleashClient) {
    this.unleash.on(EVENTS.UPDATE, () => this.#statusChanges$.next());
  }

  get enabledTogglesChanges$(): Observable<string[]> {
    return this.#statusChanges$.pipe(
      map(() => this.enabledToggles),
      startWith(this.enabledToggles),
    );
  }

  get enabledToggles(): string[] {
    return this.unleash
      .getAllToggles()
      .filter(({ enabled }) => Boolean(enabled))
      .map(({ name }) => name);
  }

  async start(): Promise<void> {
    await this.unleash.start();
  }

  async updateContext(context: IMutableContext): Promise<void> {
    await this.unleash.updateContext({
      ...context,
      properties: {
        ...this.unleash.getContext().properties,
        ...context.properties,
      },
    });
  }

  update(): void {
    this.unleash.emit(EVENTS.UPDATE);
  }

  ngOnDestroy(): void {
    this.unleash.stop();
  }
}
