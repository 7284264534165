import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  HorizontalAlignment,
  IgxToastComponent,
  PositionSettings,
  VerticalAlignment,
} from '@infragistics/igniteui-angular';

import { VersionCheckService } from '../../services';

@Component({
  selector: 'supy-version-check',
  templateUrl: './version-check.component.html',
  styleUrls: ['./version-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionCheckComponent {
  @ViewChild(IgxToastComponent, { static: true }) readonly toast: IgxToastComponent;

  readonly positionSettings: PositionSettings = {
    horizontalDirection: HorizontalAlignment.Center,
    verticalDirection: VerticalAlignment.Bottom,
  } as const;

  private remoteVersionHash: string;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly versionCheck: VersionCheckService,
  ) {
    this.versionCheck.versionChanged$.pipe(takeUntilDestroyed()).subscribe(hash => {
      this.remoteVersionHash = hash;
      this.open();
    });
  }

  open(): void {
    this.toast.open();
    this.cdr.markForCheck();
  }

  refresh(): void {
    localStorage.setItem('version', this.remoteVersionHash);
    this.document.location.href = '/refresh';
  }
}
