import { filter, interval, merge } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import * as intercom from '@intercom/messenger-js-sdk';
import { IntercomSettings } from '@intercom/messenger-js-sdk/dist/types';

import { IntercomConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

export interface IntercomUser {
  readonly email?: string;
  readonly phone?: string;
  readonly id?: string;
  readonly createdAt?: Date;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly intercomUserHash?: string;
}

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor(
    private readonly router: Router,
    @Inject(APP_CONFIG) private readonly config: IntercomConfig,
  ) {
    if (config.intercom.disabled) {
      return;
    }

    intercom.Intercom(this.defaultSettings);

    const navigate$ = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    merge(interval(30_000), navigate$)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        intercom.update({});
      });
  }

  get defaultSettings(): IntercomSettings {
    return {
      app_id: this.config.intercom.appId,
      hide_default_launcher: true,
      alignment: 'left',
    };
  }

  show(): void {
    intercom.show();
  }

  hide(): void {
    intercom.hide();
  }

  start(user: IntercomUser): void {
    const { email, phone, id, createdAt, firstName, lastName, intercomUserHash } = user;

    const data: IntercomSettings = {
      email,
      phone,
      user_id: id,
      created_at: new Date(createdAt).getTime(),
      name: `${firstName} ${lastName}`,
      user_hash: intercomUserHash,
      ...this.defaultSettings,
    };

    intercom.boot(data);
  }

  shutdown(): void {
    intercom.shutdown();
  }
}
