import { concat, filter, first, from, interval, map, Observable, race, Subject, switchMap } from 'rxjs';
import { ApplicationRef, Inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { VersionCheckConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

interface VersionCheckResponse {
  readonly hash: string;
}

@Injectable()
export class VersionCheckService {
  get currentVersionHash(): string {
    return this.config.versionCheck.hash;
  }

  readonly versionChanged$ = new Subject<string>();

  constructor(
    private readonly appRef: ApplicationRef,
    @Inject(APP_CONFIG) private readonly config: VersionCheckConfig,
  ) {
    if (this.config.versionCheck.disabled) {
      return;
    }

    this.subscribe();
  }

  private subscribe(): void {
    const appTimeout = 30_000;
    const checkInterval = 30_000;
    const appStartDelay$ = interval(appTimeout).pipe(first());
    const appIsStable$ = this.appRef.isStable.pipe(first(Boolean));
    const appStarted$ = race(appIsStable$, appStartDelay$);
    const checkInterval$ = interval(checkInterval);
    const onceAppIsStable$ = concat(appStarted$, checkInterval$);

    onceAppIsStable$
      .pipe(
        takeUntilDestroyed(),
        switchMap(() => this.poll()),
      )
      .subscribe(this.versionChanged$);
  }

  private poll(): Observable<string> {
    return from(import('yaml')).pipe(
      switchMap(({ parse }) =>
        from(
          fetch('/assets/version.yaml', {
            cache: 'no-store',
          }),
        ).pipe(
          switchMap(response => response.text()),
          map(version => parse(version) as VersionCheckResponse),
          map(({ hash }) => hash),
          filter(Boolean),
          filter(hash => this.currentVersionHash !== hash),
        ),
      ),
    );
  }
}
