export abstract class BaseStorageService {
  protected abstract readonly storage: Storage;

  get keys(): string[] {
    return Object.keys(this.storage);
  }

  clear(): void {
    this.storage.clear();
  }

  getItem<T>(key: string): T | null {
    const item = this.storage.getItem(key);

    if (item) {
      return JSON.parse(item) as T;
    }

    return null;
  }

  removeItem(key: string): void {
    return this.storage.removeItem(key);
  }

  setItem<T>(key: string, value: T): void {
    const stringValue = JSON.stringify(value);

    return this.storage.setItem(key, stringValue);
  }
}
