export function extractCommitHash(version: string | undefined): string | undefined {
  const commitHashPattern = /([a-f0-9]{9})$/;

  if (version === 'local') {
    return version;
  }

  const match = version?.match(commitHashPattern);

  return match?.[0];
}
