import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { INTERCOM_CONFIG, IntercomModuleOptions } from './intercom-module.options';
import { IntercomService } from './services';

@NgModule({
  imports: [RouterModule],
})
export class IntercomModule {
  static forRoot(config: IntercomModuleOptions): ModuleWithProviders<IntercomModule> {
    return {
      ngModule: IntercomModule,
      providers: [IntercomService, { provide: INTERCOM_CONFIG, useValue: config }],
    };
  }
}
