import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
  CaptchaModule,
  CodeInputModule,
  CountdownModule,
  IconModule,
  ImageModule,
  InputWithButtonModule,
} from '@supy/components';
import { LayoutsModule } from '@supy/layouts';

import {
  AuthenticationComponent,
  LoginWithEmailComponent,
  LoginWithPhoneComponent,
  LogoutComponent,
  OtpComponent,
} from './components';

@NgModule({
  declarations: [
    AuthenticationComponent,
    LogoutComponent,
    OtpComponent,
    LoginWithPhoneComponent,
    LoginWithEmailComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    InputWithButtonModule,
    CodeInputModule,
    IconModule,
    CountdownModule,
    ImageModule,
    LayoutsModule,
    CaptchaModule,
  ],
})
export class AuthenticationModule {}
