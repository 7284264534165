import { CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import { getLocaleDirection } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  input,
  LOCALE_ID,
  OnChanges,
  OnInit,
  Optional,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
  viewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { KeyboardKey } from '@supy/common';

import { InputComponent, InputDensity } from '../../input';
import { TextareaComponent } from '../../textarea';
import { DropdownTreeNode } from '../models';
import {
  collapseNodesRecursively,
  resetHiddenAndExpandedNodesRecursively,
  setNodeHiddenStatus,
  toggleNodeExpandAndChildren,
} from './dropdown-tree.helper';
import { DROPDOWN_TREE_LIST_POSITIONS } from './dropdown-tree.positions';

export type DropdownTreeSearchMethod = 'filter' | 'suggest-node';

@Component({
  selector: 'supy-dropdown-tree',
  templateUrl: './dropdown-tree.component.html',
  styleUrls: ['./dropdown-tree.component.scss'],
})
export class DropdownTreeComponent<T = string> implements OnInit, OnChanges, ControlValueAccessor {
  readonly searchInput = viewChild<InputComponent<string>>(InputComponent);
  readonly textArea = viewChild<TextareaComponent>(TextareaComponent);

  @Input() set data(value: DropdownTreeNode<T>[]) {
    if (value) {
      this.#data = structuredClone(value);
      this.init(this.#value);
    }
  }

  get data(): DropdownTreeNode<T>[] {
    return this.#data;
  }

  @Input() set value(key: T | T[]) {
    this.init(key || []);
    this.#value = key || [];
  }

  @Input() name: string;
  @Input() minSearchValue = 3;
  @Input() placeholder: string;
  @Input() disabled = false;
  @Input() readOnly = false;
  @Input() returnStrategy: 'key' | 'object' = 'key';
  @Input() displayStrategy: 'value' | 'path' = 'value';
  @Input() selection: 'single' | 'multiple' = 'single';
  @Input() multipleSelectionStrategy: 'node' | 'children' = 'node';
  @Input() clearable = true;
  @Input() density: InputDensity = 'small';
  @Input() variant: 'border' | 'flat' = 'border';
  @Input() autoExpanded: boolean;
  @Input() readonly isLoading: boolean;
  @Input() autoWidth: boolean;
  @Input() customTrigger: CdkOverlayOrigin;

  readonly searchMethod = input<DropdownTreeSearchMethod>('filter');

  @Output() valueChange = new EventEmitter();
  @Output() closed = new EventEmitter<void>();
  @ViewChild('dropdownOverlay') dropdownOverlay: ElementRef<HTMLElement>;

  readonly focusedNodeId = signal<T>(null);

  #data: DropdownTreeNode<T>[] = [];
  #value: T | T[] = [];

  protected readonly listPositions: ConnectionPositionPair[] = DROPDOWN_TREE_LIST_POSITIONS;

  get computedClearable(): boolean {
    return (
      !this.disabled &&
      !this.readOnly &&
      this.clearable &&
      (Array.isArray(this.#value) ? this.#value.length > 0 : Boolean(this.#value))
    );
  }

  placeholderValue: string;

  treeNodeValue: DropdownTreeNode<T>;

  overlayDisplayed = signal<boolean>(false);
  suggestedNode: DropdownTreeNode<T>;
  nodeIdPrefix = 'tree-node-id';
  path: DropdownTreeNode<T>[] = [];

  private selectedNodes: DropdownTreeNode<T>[];
  private readonly dir: 'ltr' | 'rtl';

  get treeNodeValuePath(): string {
    return this.path.map(node => node.name).reduce((prev, curr) => (!prev ? `${curr}` : `${prev} > ${curr}`), null);
  }

  get isAllVisibleNodesSelected(): boolean {
    return this.flattenTree(this.data)
      .filter(node => !node.hidden && !node.unselectable)
      .every(node => node.selected);
  }

  get isAllNodesHidden(): boolean {
    return this.data.every(node => node.hidden);
  }

  get selectedNodesPath(): string {
    return this.selectedNodes
      .map(node => node.name)
      .reduce((prev, curr) => (!prev ? `${curr}` : `${prev}, ${curr}`), null);
  }

  get textAreaValue() {
    return this.selection === 'single'
      ? this.displayStrategy === 'value'
        ? (this.treeNodeValue?.name ?? '')
        : this.treeNodeValuePath
      : this.selectedNodes?.length > 0
        ? this.displayStrategy === 'value'
          ? `(${this.selectedNodes.length}) ${this.placeholderValue}`
          : this.selectedNodesPath
        : '';
  }

  get textAreaBoundingRect() {
    const innerRectangle = this.element?.nativeElement?.getBoundingClientRect();
    const rem = parseFloat(getComputedStyle(document.documentElement).fontSize);

    return {
      top: innerRectangle?.top + rem,
      left: innerRectangle?.left + (this.dir === 'ltr' ? rem : -rem),
      width: innerRectangle?.width,
    };
  }

  onChange: (value: DropdownTreeNode<T>) => void;
  onTouched: () => void;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly element: ElementRef<HTMLElement>,
    @Inject(LOCALE_ID) private readonly locale: string,
    @Optional() @Inject(NgControl) private readonly control?: NgControl,
  ) {
    this.dir = getLocaleDirection(this.locale);
  }

  ngOnInit() {
    this.cdr.detach();

    if (this.control) {
      this.control.valueAccessor = this;
      this.control.control?.valueChanges.subscribe((key: T | T[]) => {
        if (this.data) {
          this.init(key);
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.placeholder) {
      this.placeholderValue = changes.placeholder.currentValue as string;
      this.cdr.detectChanges();
    }

    if (this.control && this.data) {
      this.init(this.control.control.value as T);
    }
  }

  protected onTextAreaKeykeyDown(event: KeyboardEvent): void {
    const key = event.key as KeyboardKey;

    if (key === KeyboardKey.ArrowDown) {
      event.preventDefault();

      this.open();
    }
  }

  @HostListener('document:mousedown', ['$event'])
  private onMouseDown(event: MouseEvent): void {
    if (!this.dropdownOverlay?.nativeElement.contains(event.target as Element)) {
      this.overlayDisplayed.set(false);
      this.closed.emit();
      this.cdr.detectChanges();
    }
  }

  @HostListener('document:keydown', ['$event'])
  private onKeyDown(event: KeyboardEvent): void {
    const key = event.key as KeyboardKey;

    if (!this.overlayDisplayed()) {
      return;
    }

    // Allow input to handle the event
    const eventkKeys = [
      KeyboardKey.ArrowDown,
      KeyboardKey.ArrowUp,
      KeyboardKey.Enter,
      KeyboardKey.Tab,
      KeyboardKey.Escape,
      KeyboardKey.Space,
    ];

    if (!eventkKeys.includes(key) && this.searchInput()?.domInput?.focused) {
      return;
    }

    this.searchInput()?.domInput?.nativeElement?.blur();

    const selectAllNode: DropdownTreeNode<T> = {
      id: 'selectAll' as unknown as T,
      name: 'Select all',
    };

    const flatTree = [...(this.selection === 'multiple' ? [selectAllNode] : []), ...this.flattenTree(this.data)];

    const isNodeVisible = (node: DropdownTreeNode<T>, index: number): boolean => {
      if (node.hidden) {
        return false;
      }

      // A node is visible if none of its ancestors are collapsed
      for (let i = index - 1; i >= 0; i--) {
        if (flatTree[i].children?.includes(node)) {
          return flatTree[i].expanded && !flatTree[i].hidden;
        }
      }

      return true;
    };

    const findVisibleNodeIndex = (currentIndex: number, direction: 'next' | 'prev'): number => {
      const step = direction === 'next' ? 1 : -1;

      for (let i = currentIndex + step; i >= 0 && i < flatTree.length; i += step) {
        if (isNodeVisible(flatTree[i], i)) {
          return i;
        }
      }

      // Wrap around
      if (direction === 'next') {
        for (let i = 0; i < currentIndex; i++) {
          if (isNodeVisible(flatTree[i], i)) {
            return i;
          }
        }
      } else {
        for (let i = flatTree.length - 1; i > currentIndex; i--) {
          if (isNodeVisible(flatTree[i], i)) {
            return i;
          }
        }
      }

      return -1;
    };

    const focusedIndex = flatTree.findIndex(node => node.id === this.focusedNodeId());

    switch (key) {
      case KeyboardKey.Escape: {
        this.overlayDisplayed.set(false);
        this.closed.emit();
        this.cdr.detectChanges();

        break;
      }

      case KeyboardKey.ArrowDown: {
        event.preventDefault();

        const nextIndex = findVisibleNodeIndex(focusedIndex, 'next');

        if (nextIndex >= 0) {
          this.updateFocus(flatTree[nextIndex]?.id);
        }

        break;
      }

      case KeyboardKey.ArrowUp: {
        event.preventDefault();

        const prevIndex = findVisibleNodeIndex(focusedIndex, 'prev');

        if (prevIndex >= 0) {
          this.updateFocus(flatTree[prevIndex]?.id);
        }

        break;
      }

      // prevent default behavior
      case KeyboardKey.Enter:
      case KeyboardKey.Tab: {
        event.preventDefault();

        break;
      }

      // this should expand the node
      case KeyboardKey.ArrowRight: {
        const focusedNode = flatTree[focusedIndex];

        if (focusedNode?.children?.length) {
          toggleNodeExpandAndChildren<T>({ node: focusedNode, expand: true, toggleChildren: false });
          this.cdr.detectChanges();
        }

        break;
      }

      // this should collapse the node
      case KeyboardKey.ArrowLeft: {
        const focusedNode = flatTree[focusedIndex];

        if (focusedNode?.children?.length) {
          toggleNodeExpandAndChildren<T>({ node: focusedNode, expand: false, toggleChildren: true });
          this.cdr.detectChanges();
        }

        break;
      }

      case KeyboardKey.Space: {
        event.preventDefault();

        const focusedNode = flatTree[focusedIndex];

        if (focusedNode) {
          if (focusedNode.id === 'selectAll') {
            this.onToggleAll(!this.isAllVisibleNodesSelected);

            break;
          }

          if (!focusedNode.unselectable) {
            if (this.selection === 'single') {
              this.onSelect(focusedNode);
            } else {
              this.onSelectMultiple(!focusedNode.selected, focusedNode);
            }
          }
        }

        break;
      }
    }
  }

  private flattenTree(nodes: DropdownTreeNode<T>[]): DropdownTreeNode<T>[] {
    const result: DropdownTreeNode<T>[] = [];

    nodes.forEach(node => {
      result.push(node);

      if (node.children?.length) {
        result.push(...this.flattenTree(node.children));
      }
    });

    return result;
  }

  private updateFocus(nodeId: T): void {
    this.focusedNodeId.set(nodeId);
    this.scrollIntoView(nodeId);
    this.cdr.detectChanges();
  }

  onExpand(event: Event, node: DropdownTreeNode<T>): void {
    event.stopPropagation();
    toggleNodeExpandAndChildren<T>({ node, expand: !node.expanded, toggleChildren: node.expanded });
    this.cdr.detectChanges();
  }

  onSelect(node: DropdownTreeNode<T>): void {
    if (node.id === this.treeNodeValue?.id) {
      this.overlayDisplayed.set(false);
      this.closed.emit();
      this.cdr.detectChanges();

      return;
    }

    if (this.control) {
      this.control.control.setValue(node?.id);
    }

    this.writeValue(node);
    this.valueChange.emit(this.returnStrategy === 'key' ? node?.id : node);
    this.overlayDisplayed.set(false);
    this.cdr.detectChanges();
  }

  onClear(e: MouseEvent): void {
    if (this.disabled) {
      return;
    }

    e.stopPropagation();

    if (this.control) {
      this.control.control.setValue(null);
    }
    this.writeValue(null);
    this.valueChange.emit(null);
    this.cdr.detectChanges();
  }

  onToggleAll(checked: boolean) {
    this.data.forEach(node => this.onSelectMultiple(checked, node));
  }

  onSelectMultiple(checked: boolean, node: DropdownTreeNode<T>): void {
    this.selectNode(() => checked, node);

    const selectedNodes = this.getSelectedNodes(this.data);
    const selectedNodesIds = selectedNodes?.map(node => node.id);

    if (this.control) {
      this.control.valueAccessor = this;
      this.control.control.setValue(selectedNodesIds);
    }

    this.valueChange.emit(selectedNodesIds);
    this.selectedNodes = selectedNodes;
    this.cdr.detectChanges();
  }

  open(): void {
    this.resetHiddenAndExpandedNodes();

    if (this.treeNodeValue && this.selection === 'single') {
      this.setTreeExpandStatus(this.data, this.treeNodeValue.id, { toggle: false, toggleChildren: this.autoExpanded });
      this.searchInTree(this.data, null, this.treeNodeValue.id).expanded = false;
      this.scrollIntoView(this.treeNodeValue.id);

      this.focusedNodeId.set(this.treeNodeValue.id);
    }

    this.overlayDisplayed.set(true);
    this.suggestedNode = null;
    this.cdr.detectChanges();
  }

  filterNodes(term: string): void {
    if (this.focusedNodeId()) {
      this.focusedNodeId.set(null);
    }

    this.resetHiddenAndExpandedNodes();

    this.setHiddenNodes(this.data, term.trim());

    if (term.trim().length) {
      this.expandVisibleNodes();
    }

    this.cdr.detectChanges();
  }

  collapseSelectedNodes(): void {
    this.data.forEach(node => {
      collapseNodesRecursively(node);
    });

    this.cdr.detectChanges();
  }

  setHiddenNodes(tree: DropdownTreeNode<T>[], term: string): void {
    tree.forEach(node => {
      setNodeHiddenStatus(node, term);
    });
  }

  resetHiddenAndExpandedNodes(): void {
    this.data.forEach(node => {
      resetHiddenAndExpandedNodesRecursively(node);
    });
    this.cdr.detectChanges();
  }

  expandVisibleNodes(): void {
    this.data.forEach(node => {
      toggleNodeExpandAndChildren<T>({ node, expand: true, toggleChildren: true, onlyVisible: true });
    });

    this.cdr.detectChanges();
  }

  suggestNode(value: string): void {
    this.suggestedNode = null;

    if (value.length >= this.minSearchValue) {
      this.suggestedNode = this.searchInTree(this.data, value);
      this.focusedNodeId.set(this.suggestedNode?.id ?? this.focusedNodeId() ?? null);
    }

    const key = this.suggestedNode
      ? this.suggestedNode.id
      : this.selection === 'single'
        ? this.treeNodeValue?.id
        : this.data[0].id;

    this.setTreeExpandStatus(this.data, key);
    this.cdr.detectChanges();
    this.scrollIntoView(key);
  }

  onSearch(term: string): void {
    if (this.searchMethod() === 'filter') {
      this.filterNodes(term);
    } else {
      this.suggestNode(term);
    }
  }

  writeValue(value: DropdownTreeNode<T> | string[]): void {
    this.#value = value as T;
    this.treeNodeValue = value as DropdownTreeNode<T>;

    if (Array.isArray(value) && this.data) {
      this.init(value as T[]);
    }
  }

  registerOnChange(fn: (value: DropdownTreeNode<T>) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  private init(key: T | T[]): void {
    switch (this.selection) {
      case 'single':
        this.initForOneSelection(key as T);
        break;
      case 'multiple':
        this.initForMultipleSelection(key as T[]);
        break;
    }

    this.cdr.detectChanges();
  }

  private initForOneSelection(key: T): void {
    this.writeValue(this.searchInTree(this.data, null, key));
    this.setTreeExpandStatus(this.data, key);
    this.setTreeSelectedStatus(this.data, key);

    if (this.displayStrategy === 'path') {
      this.path = this.findPath(this.data);
    }
  }

  private initForMultipleSelection(keys: T[]): void {
    this.data.forEach(node => {
      this.selectNode((id: T) => (keys ? keys.includes(id) : false), node);

      if (this.multipleSelectionStrategy === 'children') {
        this.setNodeChildrenSelectedState(node);
      }
    });

    this.selectedNodes = this.getSelectedNodes(this.data);
  }

  private findPath(tree: DropdownTreeNode<T>[], pathArr: DropdownTreeNode<T>[] = []): DropdownTreeNode<T>[] {
    return tree.reduce((accArr, node) => {
      if (node.selected) {
        accArr.push(node);
      }

      if (node.children?.length) {
        return this.findPath(node.children, accArr);
      }

      return accArr;
    }, pathArr);
  }

  private selectNode(selected: (id?: T) => boolean, node: DropdownTreeNode<T>) {
    if (!node.hidden) {
      node.selected = selected(node.id);
    }

    node.children?.forEach(node => {
      this.selectNode(selected, node);
    });
  }

  private setTreeExpandStatus(
    tree: DropdownTreeNode<T>[],
    key: T,
    toggleOptions: { toggle: boolean; toggleChildren?: boolean } = { toggle: false, toggleChildren: true },
  ): void {
    tree.forEach(node => {
      node.expanded = this.isNodeExpanded(node, key, toggleOptions.toggle);

      if (node.expanded && node.children?.length) {
        if (toggleOptions.toggleChildren) {
          node.children.forEach(child => (child.expanded = true));
        } else {
          this.setTreeExpandStatus(node.children, key, toggleOptions);
        }
      }
    });
  }

  private setTreeSelectedStatus(tree: DropdownTreeNode<T>[], key: T): boolean {
    let found = false;

    for (const node of tree) {
      if (node.id === key) {
        node.selected = true;
        found = true;
        continue;
      }

      if (node.children?.length) {
        node.selected = this.setTreeSelectedStatus(node.children, key);
      } else {
        node.selected = false;
      }
    }

    return found;
  }

  private isNodeExpanded(node: DropdownTreeNode<T>, key: T, toggle?: boolean): boolean {
    if (node.id === key) {
      return toggle ? !node.expanded : true;
    } else {
      return node.children?.length ? this.isNodeContainValue(node.children, key) : false;
    }
  }

  private isNodeContainValue(children: DropdownTreeNode<T>[], key: T): boolean {
    return children.reduce((prev, curr) => {
      if (prev) {
        return prev;
      }

      return this.isNodeExpanded(curr, key);
    }, false);
  }

  private searchInTree(tree: DropdownTreeNode<T>[], value?: string, key?: T): DropdownTreeNode<T> {
    return tree?.reduce((prev, curr) => {
      if (prev) {
        return prev;
      }

      if (key && curr.id && curr.id === key) {
        return curr;
      }

      if (value && curr.name.toLowerCase().includes(value.toLowerCase())) {
        return curr;
      }

      return this.searchInTree(curr.children, value, key);
    }, null);
  }

  private getAllChildrenAsArray(node: DropdownTreeNode<T>): DropdownTreeNode<T>[] {
    if (!node.children?.length) {
      return [];
    }

    return [
      ...node.children,
      ...node.children
        .reduce((arr: DropdownTreeNode<T>[], currChild) => {
          if (!currChild.children) {
            return [];
          }

          arr.push(...this.getAllChildrenAsArray(currChild));

          return arr;
        }, [])
        .flat()
        .filter(Boolean),
    ];
  }

  private getSelectedNodes(tree: DropdownTreeNode<T>[]): DropdownTreeNode<T>[] {
    return this.convertTreeToArray(tree).reduce<DropdownTreeNode<T>[]>((acc, node) => {
      if (node.selected && !node.unselectable) {
        acc.push(node);
      }

      return acc;
    }, []);
  }

  private convertTreeToArray(tree: DropdownTreeNode<T>[]): DropdownTreeNode<T>[] {
    return tree
      .reduce((arr: DropdownTreeNode<T>[], node) => {
        arr.push(node, ...this.getAllChildrenAsArray(node));

        return arr;
      }, [])
      .flat();
  }

  private setNodeChildrenSelectedState(node: DropdownTreeNode<T>) {
    node.childrenSelected = this.getChildrenSelectionState(node);
    node.selected = node.childrenSelected === 'all';

    node.children?.forEach(node => {
      this.setNodeChildrenSelectedState(node);
    });
  }

  private getChildrenSelectionState(node: DropdownTreeNode<T>): 'all' | 'some' | 'none' {
    const childrenArray = this.getAllChildrenAsArray(node).filter(node => !node.unselectable);
    const selectedCount = childrenArray.reduce((count, child) => {
      if (child?.selected) {
        count++;
      }

      return count;
    }, 0);

    return childrenArray.length === selectedCount && (childrenArray.length > 0 || node.selected)
      ? 'all'
      : selectedCount > 0
        ? 'some'
        : 'none';
  }

  private scrollIntoView(key: T): void {
    setTimeout(() =>
      document
        .getElementById(`${this.nodeIdPrefix}:${key as unknown as string}`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' }),
    );
  }
}
